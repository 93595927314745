@import "../variables.scss";

$horizontal_breakpoint: 500px;
$img_size: 173px;

.footer {
  margin-top: 6rem;
  overflow: hidden;
  position: relative;

  &:before {
    content: "";
    top: 0;
    left: 0;
    right: 0;
    height: 8px;
    position: absolute;
    background: linear-gradient(to right, $primary, darken($primary, 30));

    @media all and (min-width: $horizontal_breakpoint) {
      background: linear-gradient(to left, $primary, darken($primary, 30));
    }
  }

  @media print {
    margin-top: 0;

    &:before {
      display: none;
    }
  }

  .contact {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 2rem $gutter;
    margin: 0 auto;
    position: relative;
    max-width: $max_width;
    overflow: hidden;

    figure {
      position: absolute;
      bottom: 0;
      right: -10px;
      display: block;
      z-index: -1;
      display: flex;
      flex-direction: column;
      margin: 0;
      width: $img_size;
      height: $img_size;

      img {
        position: absolute;
        transition: transform 0.25s ease;
      }

      .uncopied-img {
        transform: translateY(0);
      }

      .copied-img,
      .failed-img {
        transform: translateY(500px);
      }

      &.copied {
        .uncopied-img,
        .failed-img {
          transform: translateY(500px);
        }

        .copied-img {
          transform: translateY(0);
        }
      }

      &.failed {
        .uncopied-img,
        .copied-img {
          transform: translateY(500px);
        }

        .failed-img {
          transform: translateY(0);
        }
      }

      @media all and (min-width: $horizontal_breakpoint) {
        right: unset;
        left: -10px;
        transform: rotateY(180deg);
      }

      @media all and (min-width: 768px) {
        left: 10%;
      }
    }

    h3 {
      flex-basis: 100%;
      text-align: center;
      margin-bottom: 2rem;
      text-shadow: 0 0 4px black;
    }

    @media print {
      max-width: none;
      padding: 0;
    }
  }

  .meta {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    max-width: $max_width;
    margin: 0 auto;
    padding: 2rem $gutter 0;
    border-top: 1px solid rgba(white, 0.5);
    overflow: hidden;
  }

  .copyright,
  .social {
    margin-top: 1rem;
    margin-bottom: 1rem;
    flex-basis: 100%;
  }

  .social {
    display: flex;
    justify-content: center;

    svg {
      width: 1rem;
      height: 1rem;

      @media all and (min-width: 275px) {
        width: 1.5rem;
        height: 1.5rem;
      }

      @media all and (min-width: 350px) {
        width: 2rem;
        height: 2rem;
      }
    }

    a {
      display: block;

      + a {
        margin-left: 1rem;
      }

      @media print {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        font-size: 0.65rem;

        &:after {
          content: attr(href);
        }

        svg {
          width: 1.5rem;
          height: 1.5rem;
          margin-right: 0.5rem;
        }
      }
    }

    .gap {
      position: relative;
      margin-left: 2rem;
      padding-left: 2rem;
      display: flex;

      &:before {
        content: "";
        width: 1px;
        top: 0;
        bottom: 0;
        left: 0;
        position: absolute;
        background: rgba(white, 0.5);

        @media all and (min-width: 632px) {
          top: -1.75rem;
          bottom: -1.75rem;
        }
      }
    }
  }

  .copyright {
    font-size: 0.75rem;
    line-height: 0.25rem;
    font-weight: 200;
    color: rgba(white, 0.75);
    text-align: center;
    vertical-align: middle;

    img {
      vertical-align: middle;
      margin-right: 0.5rem;
    }

    @media print {
      color: black;
    }
  }

  @media all and (min-width: 632px) {
    .meta {
      justify-content: space-between;
      padding: 0.5rem $gutter 0;
    }

    .copyright,
    .social {
      flex-basis: auto;
    }

    .social {
      margin-right: 1rem;
    }
  }

  @media print {
    .meta {
      max-width: none;
      padding: 0;
      margin: 0;
    }

    .social {
      margin: 0.5rem 0 0;
      justify-content: flex-start;
    }
  }

  .contact-button {
    max-width: 100%;
    max-height: 4rem;
    display: flex;
    justify-content: center;
    position: relative;
    flex-wrap: wrap;
    margin-bottom: 10rem;

    @media all and (min-width: $horizontal_breakpoint) {
      margin-bottom: 1rem;
    }

    @media print {
      max-height: none;
    }

    /*button*/
    .copy {
      border-radius: 0 2rem 2rem 0;
      border: 2px solid white;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: white;
      padding: 0;
      order: 2;
      width: 4.5rem;
      height: 4rem;
      position: relative;
      overflow: hidden;

      &.copied,
      &.failed {
        cursor: unset;
      }

      .icon-wrap {
        transition: transform 0.25s ease;
        position: absolute;
        left: 1rem;
        top: 0;
        min-height: 12rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        transform: translateY(-4rem);

        &.success {
          transform: translateY(0);
        }

        &.failed {
          transform: translateY(-8rem);
        }
      }

      .fill-target {
        fill: black;
      }

      &:disabled {
        cursor: unset;
      }

      .icon {
        transition: transform 0.25s ease;
      }

      &:hover .icon {
        transform: scale(1.1);
      }

      &:active .icon {
        transform: scale(0.9);
      }

      &:focus-visible {
        outline: none;
        border-color: $primary;
        background: $primary;
      }
    }

    svg {
      width: 1.5rem;
      height: 1.5rem;

      @media all and (min-width: 350px) {
        width: 2rem;
        height: 2rem;
      }
    }

    .email-wrap {
      display: flex;
      flex-direction: column;
    }

    .email,
    .width-holder {
      font: inherit;
      font-size: 0.75rem;
      max-height: 4rem;

      @media all and (min-width: 350px) {
        font-size: 1rem;
      }

      @media all and (min-width: 450px) {
        font-size: 1.25rem;
      }
    }

    .width-holder {
      max-height: 0;
      overflow: hidden;
      padding: 0 1rem;
      white-space: nowrap;
    }

    .email {
      color: white;
      text-align: center;
      padding: 1rem 0;
      background: black;
      border: 2px solid white;
      border-radius: 2rem 0 0 2rem;
      margin: 0;
      min-height: 100%;

      &:focus {
        outline: none;
        border-color: $primary;
      }

      &::selection {
        background: $primary;
        color: black;
      }

      @media print {
        min-height: unset;
        background-color: transparent;
        color: black;
        white-space: nowrap;
        border: 0;
        padding: 0;
      }
    }

    .message {
      position: absolute;
      font-size: 0.75rem;
      margin-top: 0.5rem;
      top: 100%;
    }
  }

  .fill-target {
    fill: white;

    @media print {
      fill: black;
    }
  }

  a:hover {
    .fill-target {
      fill: $primary;
    }
  }

  .url {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
  }
}
