@import "../variables.scss";

$black: darken($primary, 35);
$logo_area: 3.5rem;
$logo_size: 1.75rem;
$logo_padding: 1rem;
$horizontal_breakpoint: 724px;

.history {
  max-width: $max_width;
  margin-left: auto;
  margin-right: auto;

  h3,
  p {
    max-width: 30rem;

    @media print {
      max-width: none;
    }
  }

  @media all and (min-width: $horizontal_breakpoint) {
    display: flex;
    justify-content: center;

    header {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  @media print {
    max-width: none;
    justify-content: flex-start;
    margin-top: 1rem;
    margin-bottom: -1.25rem;
  }

  main {
    text-align: center;
  }

  .card {
    color: $black;
    max-width: 24rem;
    margin: 2.5rem auto;
    display: inline-block;
    text-align: left;
    padding-bottom: calc(#{$card_padding} / 4);
    position: relative;
    perspective: 800px;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: $primary;
      background-image: linear-gradient(
        to top left,
        darken($primary, 8),
        $primary
      );
      border-radius: $card_radius;
      top: -1rem;
      right: -1rem;
      bottom: -1rem;
      left: -1rem;
      transform: rotateX(1deg) rotateY(350deg) translateZ(-30px)
        translatex(-10px);
    }

    .card-content {
      transform: rotateY(4deg);
    }

    @media all and (min-width: $horizontal_breakpoint) {
      margin: 2.5rem 2rem;
    }

    h3 {
      font-size: 2rem;
      line-height: 1.875rem;
      padding: 0 $card_padding;
      margin: 1.25rem 0 1.5rem;
      position: relative;
      z-index: 1;
    }

    @media print {
      margin: 0;
      max-width: none;
      display: block;
      color: black;

      &:before {
        display: none;
      }

      .card-content {
        transform: none;
      }

      h3 {
        padding: 0;
        font-size: 1.25rem;
        line-height: 1.25rem;
        margin: 0 0 1rem;
      }
    }
  }

  .item {
    list-style-type: none;
    padding: 0;
    page-break-inside: avoid;
    position: relative;
    margin-bottom: 0.5rem;
    padding-bottom: 0.25rem;

    &:after {
      content: "";
      position: absolute;
      bottom: 0.5rem;
      left: calc(-#{$logo_padding} - #{$logo_area});
      right: 0;
      height: 2px;
      background: rgba($black, 0.25);
    }

    &:last-of-type {
      margin-bottom: 0;

      &:after {
        display: none;
      }
    }
  }

  dl,
  dt,
  dd {
    margin: 0;
    padding: 0;
  }

  dt,
  dd {
    padding: 0 $card_padding 0 0;

    @media print {
      padding: 0;
    }
  }

  dl {
    padding-left: calc(#{$logo_area} + (#{$logo_padding} * 2));

    @media print {
      padding-left: calc(#{$logo_area} + (#{$logo_padding} * 1));
    }
  }

  dt {
    font-size: 1.125rem;
    font-weight: 600;
  }

  dd {
    padding-bottom: 1rem;
  }

  figure {
    margin: 0;
    position: absolute;
    left: calc(-#{$logo_area} - #{$logo_padding});
    // top: -1.25rem;
    top: -0.25rem;
    width: $logo_area;
    height: $logo_area;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    box-shadow: 0 4px 7px 1px rgba($black, 0.2),
      0 16px 10px -12px rgba($black, 0.4);

    @media print {
      top: -0.75rem;
      box-shadow: none;
    }

    svg {
      width: $logo_size;
      height: $logo_size;
      fill: $black;

      @media print {
        fill: black;
      }
    }
  }

  .title,
  .when {
    @media all and (min-width: $horizontal_breakpoint) {
      white-space: nowrap;
    }
  }

  .title {
    font-size: 0.875rem;
    font-weight: 600;
    display: block;
  }

  .when {
    font-size: 0.75rem;
    font-weight: 600;

    @media print {
      position: absolute;
      top: 0.25rem;
      right: 0;
    }
  }

  .notes {
    padding: 0 0 0 1rem;
    margin: 0;
    font-size: 0.875rem;
  }
}
