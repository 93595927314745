@import "../variables.scss";
$black: darken($primary, 35);

.stats {
  text-align: center;

  main {
    text-align: left;
    margin: 0 auto;

    h3,
    p {
      max-width: 30rem;

      @media print {
        max-width: none;
      }
    }
  }

  @media all and (min-width: 724px) {
    display: flex;
    align-items: center;
    justify-content: center;

    main {
      padding-right: 3rem;
      margin: 0;
    }
  }

  dl,
  dt,
  dd {
    margin: 0;
    padding: 0;
  }

  dl {
    color: $black;
    margin: 2rem 0;
    padding: 2rem;
    display: inline-block;
    text-align: left;
    max-width: 100%;
    perspective: 800px;
    position: relative;

    &:before {
      content: "";
      background-color: $primary;
      background-image: linear-gradient(
        to right,
        darken($primary, 8),
        $primary
      );
      border-radius: $card_radius;
      position: absolute;
      top: -1rem;
      right: -1rem;
      bottom: -1rem;
      left: -0.5rem;
      z-index: -1;
      transform-style: preserve-3d;
      backface-visibility: hidden;
      transform: rotateX(6deg) rotateY(14deg) rotateZ(-1deg) translateZ(-60px);
    }
  }

  li {
    list-style: none;
    padding: 0;

    dt {
      padding-bottom: 1.5rem;
    }

    &:last-of-type dt {
      padding-bottom: 0;
    }
  }

  dd,
  dt {
    border-left-style: solid;
    border-left-width: 4px;
    padding-left: 0.5rem;
  }

  dd {
    font-size: 3rem;
    line-height: 1;
    font-weight: 600;
    border-left-color: black;
    display: flex;
  }

  dt {
    font-size: 0.75rem;
    font-weight: 600;
    border-left-color: rgba(black, 0.25);
  }

  @media print {
    display: block;
    text-align: left;

    main {
      padding: 0;
    }

    dl {
      margin: 0;
      padding: 0;
      color: black;

      &:before {
        display: none;
      }
    }

    .card-content {
      display: flex;
    }

    dd {
      font-size: 2rem;
    }

    dd,
    dt {
      border: 0;
      padding-left: 0;
    }

    dt {
      padding-bottom: 0 !important;
    }

    li + li {
      margin-left: 2rem;
    }

    .languages {
      margin-top: 0.75rem;
    }
  }
}
