@import "../variables.scss";

$horizontal_breakpoint: 724px;

.hero {
  display: grid;
  justify-content: center;
  gap: 2rem;
  grid-template-columns: (minmax(auto, 30rem));
  max-width: 55rem;
  position: relative;

  .address {
    position: absolute;
    top: 0.25rem;
    right: 0;
  }

  h1 {
    font-size: 3.5rem;
    line-height: 3rem;
    margin: 0;
    color: $primary;

    @media print {
      font-size: 1.75rem;
      line-height: 1.5rem;
      color: black;
    }
  }

  h2 {
    font-size: 1.25rem;
    margin: 0.25rem 0 0;

    @media print {
      font-size: 1rem;
      margin: 0;
      color: rgba(black, 0.5);
    }
  }

  p {
    margin: 0;
  }

  figure {
    margin: 0;
    position: relative;
    z-index: -1;

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 20px;
      background: linear-gradient(to top, black, rgba(black, 0));
    }
  }

  .content {
    align-self: flex-end;
    grid-column: 1;
  }

  .more-content {
    display: grid;
    grid-column: 1 / span 2;
    gap: inherit;
    grid-template-columns: inherit;

    &:not(.visible) {
      @extend .offscreen;
    }

    @media print {
      width: unset !important;
      height: unset !important;
      overflow: unset !important;
      position: unset !important;
      top: unset !important;
      left: unset !important;
      grid-column: unset;
    }
  }

  @media all and (min-width: $horizontal_breakpoint) {
    grid-template-columns: 1fr 1fr;

    .content {
      grid-column: unset;
    }
  }

  @media print {
    grid-template-columns: none;
    gap: 0.5rem;
  }
}
