@import "./variables.scss";

*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100%;
  max-width: 100%;
  font-family: "Signika", sans-serif;
}

html {
  background: black;
  color: white;
  fill: white;
  font-size: 16px;
  overflow-x: hidden;

  @media print {
    background: white;
    color: black;
    fill: black;
  }
}

body {
  padding: 2rem 0 0;

  @media print {
    padding: 0;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-left: auto;
  margin-right: auto;
  font-family: "Work Sans", sans-serif;

  em {
    color: $primary;
    font-style: normal;

    @media print {
      color: black;
    }
  }
}

h3 {
  font-size: 3rem;
  line-height: 2.625rem;
  font-weight: 800;
  margin: 0.5rem auto;

  @media print {
    font-size: 1.25rem;
    line-height: 1.25rem;
  }
}

p {
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 2rem;
  color: rgba(255, 255, 255, 0.7);
  margin: 2rem auto;

  @media print {
    color: black;
    margin: 0.25rem auto;
    font-size: 0.875rem;
    line-height: 1rem;
  }
}

section {
  max-width: $max_width;
  margin: 0 auto;
  padding: 0 $gutter;
  overflow: hidden;

  @media print {
    max-width: none;
    page-break-inside: avoid;
    margin: 0;
    padding: 0;
  }
}

section + section {
  margin-top: 6rem;

  @media all and (min-width: 1000px) {
    margin-top: 4rem;
  }

  @media print {
    margin-top: 0.5rem;
  }
}

a {
  color: $primary;
  text-decoration: underline;

  @media print {
    color: black;
    text-decoration: none;
  }
}

.breakable {
  overflow-wrap: break-word;
}

button {
  border-radius: 2rem;
  padding: 0.5rem 1rem;
  font: inherit;
  box-shadow: none;
  margin: 0;
  cursor: pointer;
  background: black;
  border: 2px solid white;
  color: white;

  &:not(.link):hover {
    background: white;
    color: black;
    border: 2px solid transparent;
  }
}

button.link {
  appearance: none;
  border: none;
  background: transparent;
  text-decoration: underline;
  color: $primary;
  padding: unset;

  &:disabled {
    color: desaturate($color: $primary, $amount: 75);
  }

  @media print {
    color: black;
    text-decoration: none;
  }
}

.noprint {
  @media print {
    display: none !important;
  }
}

.noscreen {
  @media screen {
    display: none;
  }
}

@page {
  margin: 1cm;
}
