$light: #424242;
$dark: darken($light, 11);

.self-taught {
  max-width: 100%;

  main {
    text-align: left;

    h3,
    p {
      max-width: 30rem;

      @media print {
        max-width: none;
      }
    }
  }

  aside {
    display: flex;
    justify-content: center;
  }

  @media all and (min-width: 724px) {
    display: flex;
    justify-content: center;

    aside {
      margin-top: 1rem;
    }

    main {
      order: 2;
      margin-left: 5rem;
    }
  }

  @media print {
    justify-content: flex-start;

    main {
      margin: 0;
    }

    p {
      margin: 0;
    }
  }

  figure {
    padding: 2rem 1rem;
    margin: 0;
    border-radius: 2rem;
    max-width: calc(100% - 40px);
    background: linear-gradient(to top left, $dark, $light);
    align-self: center;
    position: relative;
    margin-bottom: 50px;

    @media all and (min-width: 724px) {
      align-self: start;
    }

    img {
      position: absolute;
      display: block;
      bottom: 0;
      right: 0;
      transform: translate3d(50px, 50px, 0);
    }
  }

  pre,
  code {
    margin: 0;
    padding: 0;
    max-width: 100%;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
  }

  code {
    font-family: "IBM Plex Mono", monospace;
    font-size: 0.875rem;

    .var {
      color: #e88cd4;
    }

    .constant {
      color: #f2e14f;
    }

    .property {
      color: #b99df4;
    }
  }

  .error {
    display: block;
    position: relative;

    &:after {
      content: attr(data-content);
      letter-spacing: -0.25rem;
      font-size: 1.5rem;
      position: absolute;
      top: calc(100% - 0.75rem);
      left: 0;
      color: #ee927b;
      font-weight: 100;
      max-width: 100%;
      overflow: hidden;
    }
  }
}
